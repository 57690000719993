import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import AnalyticsConsumer from './consumer';

export default function withAnalytics(WrappedComponent) {
  function WithAnalytics(props) {
    return (
      <AnalyticsConsumer>
        {(context) => {
          return <WrappedComponent {...props} {...context} />;
        }}
      </AnalyticsConsumer>
    );
  }

  return hoistNonReactStatics(WithAnalytics, WrappedComponent, {});
}
