// Profile
export const PROFILE = '/profile';
export const PROFILE_SETTINGS = 'settings'; // /profile/:id/settings
export const PROFILE_STATS = 'stats'; // /profile/:id/stats
export const PROFILE_HISTORY = 'history'; // /profile/:id/history
export const SELECT_GIFT_RECEIVER = '/profile/select-gift-receiver';

// Summon
export const SUMMON = '/summon';
export const SUMMON_IN_PROGRESS = '/summon/in-progress';
export const SUMMON_COMPLETE = '/summon/complete';

// Duel
export const DUEL = '/duel';
export const ASCENSION_DUEL = '/ascension-duel';
export const DUEL_BLOCK = '/duel-block';
export const MY_DUELS = '/duel-block/my-duels';
export const SELECT_CHALLENGER = 'profile/select-challenger';

// Search
export const SEARCH_WIZARDS = '/search/wizards';
export const SEARCH_PLAYERS = '/search/players';

// FAQs
export const FAQS = '/faqs';
export const FAQS_WALLETS_AND_ETHER = '/faqs/wallets-and-ether';
export const FAQS_THE_TOURNAMENT = '/faqs/the-tournament';
export const FAQS_SUMMONING_WIZARDS = '/faqs/summoning-wizards';
export const FAQS_SUMMONING_WIZARDS_GEO_BLOCK = `${FAQS_SUMMONING_WIZARDS}#why-is-my-location-preventing-me-from-summoning-a-cheeze-wizard`;
export const FAQS_DUELING = '/faqs/dueling';
export const FAQS_MISCELLANEOUS = '/faqs/miscellaneous';
export const FAQS_PARTIES = '/faqs/parties';

// General
export const ARENA = '/arena';
export const CHEEZYVERSE = '/cheezyverse';
export const HOME = '/';
export const PRIVACY_POLICY = '/privacy-policy';
export const PRIZE_POOL = '/prize-pool';
export const SIGN_IN = '/sign-in';
export const SIGN_UP = '/sign-up';
export const TERMS_OF_USE = '/terms-of-service';
export const WIZARD = '/wizard';
export const TOWER = '/tower';
export const TWITTER_CALLBACK = '/twitter-callback';

// Quests
export const QUESTS = '/quests';
export const CREAM_OF_CROP = '/quests/cream-of-crop';
export const LAST_HOTHEAD_STANDING = '/quests/last-hothead-standing';
export const LAST_KITTY_CAP_STANDING = '/quests/last-kitty-cap-standing';
export const LAST_KITTY_STANDING = '/quests/last-kitty-standing';
export const LAST_NEUTRAL_STANDING = '/quests/last-neutral-standing';
export const LAST_SUMMONER_STANDING_I = '/quests/last-summoner-standing-i';
export const LAST_SUMMONER_STANDING_II = '/quests/last-summoner-standing-ii';
export const LAST_WET_ONE_STANDING = '/quests/last-wet-one-standing';
export const LAST_WINDBAG_STANDING = '/quests/last-windbag-standing';
export const PARTY_BONUS = '/quests/party-bonus';

// Parties
export const PARTIES_ROOT = '/parties';
export const PARTIES_EXPLORE = 'explore'; // /parties/explore
export const PARTIES_CREATE = 'create'; // /parties/create
export const PARTIES_MY_PARTIES = 'my-parties'; // /parties/my-parties
export const PARTIES_DLIVE_PARTIES = 'dlive-parties'; // /parties/dlive-parties
export const PARTIES_PARTY = ''; // /parties/:id
export const PARTIES_EDIT = 'edit'; // /parties/:id/edit
export const PARTIES_ADD_WIZARDS = 'add-wizards'; // /parties/:id/add-wizards

// Exclusive wizards
export const EXCLUSIVE_WIZARDS = '/limited-edition';
export const MOLD_MAGICIANS = '/limited-edition/mold-magicians';
export const SORCERERS = '/limited-edition/sorcerers';

// Links
export const DAPPER_CHROME =
  'https://chrome.google.com/webstore/detail/dapper/pghmmgdinmfblodenlenkcnmndlnffeo';
export const MEET_DAPPER =
  'https://www.meetdapper.com/?utm_source=cheezewizards';
export const OPEN_SEA = 'https://opensea.io';
export const DISCORD = 'https://discord.gg/6RxgZrZ';

// @note: these are duplicates... see above in the "General section"
// @todo: remove dupes
export const LOGIN = '/sign-in';
export const SIGNUP = '/sign-up';

// CK Colab
export const CURDLIN_PAGE = '/profile/curdlins';
export const UPGRADE_WIZARD = '/profile/upgrade-wizard';
export const BUY_A_CURDLIN =
  'https://www.cryptokitties.co/search?include=sale&search=fancy:Curdlin';
