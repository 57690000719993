import React from 'react';
import PropTypes from 'prop-types';
import AnalyticsContext from './context';

function AnalyticsConsumer(props) {
  return (
    <AnalyticsContext.Consumer>
      {(context) => props.children(context)}
    </AnalyticsContext.Consumer>
  );
}

AnalyticsConsumer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default AnalyticsConsumer;
