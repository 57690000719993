import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './Container.css';

const Container = ({ children, className, padding, size }) => (
  <div
    className={cx(
      css.container,
      {
        [css[size]]: size,
        [css[padding]]: padding,
      },
      className,
    )}
  >
    {children}
  </div>
);

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  padding: PropTypes.oneOf([
    'gutter15',
    'gutter20',
    'gutter30',
    'gutter45',
    'gutter60',
  ]),
  size: PropTypes.oneOf([
    'full', // takes up the whole screen
    'xsmall', // 375px
    'small', // 480px
    'medium', // 540px
    'large', // 760px
    'xlarge', // 1200px
  ]),
};

Container.defaultProps = {
  className: undefined,
  padding: 'gutter30',
  size: undefined,
};

export default Container;
