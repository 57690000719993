import React from 'react';
import Loader from 'components/Loader';
import css from './ButtonLoader.css';

function ButtonLoader() {
  return (
    <div className={css.loaderWrapper}>
      <Loader />
    </div>
  );
}

export default ButtonLoader;
