import React, { Fragment, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Link from 'next/link';
import withAnalytics from 'contexts/analytics/withAnalytics';
import ButtonLoader from './ButtonLoader';
import css from './Button.css';

const innerClassName = (props) => {
  const theme = props?.theme ?? {};
  return cx(
    css.button,
    css[theme.appearance],
    css[theme.display],
    css[theme.size],
    css[theme.width],
    {
      [css.disabled]: props?.disabled || props?.isBusy,
      [css.isBusy]: props?.isBusy,
    },
    props?.className,
  );
};

const isExternalLink = (props) =>
  /^http/.test(props?.href) ||
  /^mailto/.test(props?.href) ||
  props.target === '_blank';

const isInternalLink = (props) => props?.to || props?.href || props?.as;

const pluck = (obj, keys = []) =>
  keys.reduce((acc, key) => ({ ...acc, [key]: obj[key] }), {});

const calc = (props) => {
  const trackingRef = useRef(null);

  const onClick = (...args) => {
    if (props?.disabled || props?.isBusy) return null;
    return props?.onClick?.(...args);
  };

  const defaultInnerProps = {
    className: innerClassName(props),
    'data-cy': props?.dataCy,
    onClick,
    ...pluck(props, ['id']),
  };

  if (isExternalLink(props))
    return {
      trackingRef,
      Root: Fragment,
      rootProps: {},
      Inner: 'a',
      innerProps: [
        defaultInnerProps,
        pluck(props, ['target', 'href']),
        props?.target === '_blank' && { rel: 'noopener noreferrer' },
      ]
        .filter(Boolean)
        .reduce((acc, prop) => ({ ...acc, ...prop }), {}),
    };

  if (isInternalLink(props))
    return {
      trackingRef,
      Root: Link,
      rootProps: {
        as: props?.as,
        href: props?.href,
      },
      Inner: 'a',
      innerProps: [defaultInnerProps, pluck(props, ['target', 'to'])]
        .filter(Boolean)
        .reduce((acc, prop) => ({ ...acc, ...prop }), {}),
    };

  return {
    trackingRef,
    Root: Fragment,
    rootProps: {},
    Inner: 'button',
    innerProps: [
      defaultInnerProps,
      (props?.disabled || props?.isBusy) && { disabled: true },
      pluck(props, ['target', 'to', 'href']),
    ]
      .filter(Boolean)
      .reduce((acc, prop) => ({ ...acc, ...prop }), {}),
  };
};

const Button = (props) => {
  const { Root, rootProps, Inner, innerProps, trackingRef } = calc(props);

  return (
    <Root {...rootProps}>
      <Inner {...innerProps}>
        <span
          ref={trackingRef}
          className={cx(css.buttonChildren, props?.contentClass)}
        >
          {props?.children}
        </span>
        {props?.isBusy && <ButtonLoader />}
      </Inner>
    </Root>
  );
};

Button.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  contentClass: PropTypes.string,
  dataCy: PropTypes.string,
  disabled: PropTypes.bool,
  fbTrackingProperties: PropTypes.object,
  href: PropTypes.string,
  id: PropTypes.string,
  isBusy: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['small', 'big']),
  target: PropTypes.string,
  theme: PropTypes.shape({
    appearance: PropTypes.oneOf(['blackWhite', 'colorful']),
    display: PropTypes.oneOf(['block', 'inlineBlock']),
    size: PropTypes.oneOf(['small', 'big']),
    width: PropTypes.oneOf(['grow', 'shrink', 'fill']),
  }),
  to: PropTypes.string,
  trackButtonClick: PropTypes.func,
  trackingProperties: PropTypes.object,
  type: PropTypes.string,
};

Button.defaultProps = {
  as: undefined,
  className: undefined,
  contentClass: undefined,
  dataCy: undefined,
  disabled: false,
  fbTrackingProperties: undefined,
  href: undefined,
  id: undefined,
  isBusy: false,
  onClick: () => {},
  size: undefined,
  target: undefined,
  to: undefined,
  trackButtonClick: () => {},
  trackingProperties: {},
  theme: {
    appearance: undefined,
    display: undefined,
    position: undefined,
    size: undefined,
    width: undefined,
  },
  type: 'button',
};

export default withAnalytics(Button);
